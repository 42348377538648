import { Application } from '@hotwired/stimulus'
import StimulusControllerResolver from 'stimulus-controller-resolver'
import '@hotwired/turbo-rails'
import '@uscreentv/turbo-train'
import 'form-request-submit-polyfill'
import 'styles/storefront.css'
// eslint-disable-next-line import/no-unresolved
import '@package/design-system/storefront.css'

import { StreamActions } from 'common/streams/stream_actions'

// solution to connect analytics bypassing themes and possible templates variability
document.body.dataset.controller = 'analytics'
window.Turbo.session.drive = false
window.Stimulus = Application.start()
window.Stimulus.debug = process.env.NODE_ENV === 'development'

const application = Application.start()

const definedControllers = {
  form: () => import('common/controllers/form_controller'),
  'payment-tabs': () => import('common/controllers/payment_tabs_controller'),
  analytics: async () => (await import('common/controllers/analytics_controller'))
    .analyticsControllerFactory('storefront'),
  'posthog-analytics': () => import('common/controllers/posthog_analytics_controller'),
  'live-chat': () => import('common/controllers/live_chat_controller'),
  'close-modal-on-load': () => import('common/controllers/close_modal_on_load_controller'),
  'form-image-uploader': () => import('common/controllers/form_image_uploader_controller'),
  'validate-url-input': () => import('common/controllers/validate_url_input_controller'),
  'post-comment': () => import('common/controllers/post_comment_controller'),
  'user-mentions': () => import('common/controllers/user_mentions_controller'),
  'users-liked': () => import('common/controllers/users_liked_controller'),
  'attach-video': () => import('common/controllers/attach_video_controller'),
  'cookies-manager': () => import('common/controllers/cookies_manager_controller'),
  timestamp: () => import('common/controllers/timestamp_controller'),
  'set-timezones': () => import('./controllers/set_timezones_controller'),
  'notifications-widget': () => import('./controllers/notifications_widget_controller'),
  confetti: () => import('common/controllers/confetti_controller'),
  'switch-tabs': () => import('common/controllers/switch_tabs_controller'),
}

const controllers = import.meta.glob(['./controllers/**/*_controller.js'])

StimulusControllerResolver.install(application, async controllerName => {
  if (!controllerName) return
  if (definedControllers[controllerName]) {
    const controller = await definedControllers[controllerName]()
    return controller.default || controller
  }

  const controllerPath = controllerName.replace(/--/g, '/').replace(/-/g, '_')
  const controllerKey = Object.keys(controllers).find(k => k.includes(`/${controllerPath}_controller`))
  if (controllerKey) return (await controllers[controllerKey]()).default
})

if (!window.gon?.impersonated) {
  (window.pix = window.pix || []).push()
}

document.addEventListener('turbo:before-stream-render', event => {
  const stream = event.target

  if (StreamActions[stream.action]) {
    event.preventDefault()
    StreamActions[stream.action](stream)
  }
})

document.addEventListener('turbo:frame-missing', event => {
  const location = event.detail.response.url
  event.detail.visit(location, { frame: '_top' })
})
